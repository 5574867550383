form{
    margin: 0 auto;
    box-sizing: border-box;
    input, select, textarea{        
        text-align: left;
        font-size: 1em;
        display: block;
        padding: 15px;
        width: 100%;
        max-width: 500px;
        border-radius: 5px;
        box-shadow: none;
        border: none;           
        &::placeholder {
            color: #cecccc;
        }
    }
    input[type=submit], button[type=submit] {
        cursor: pointer;
        background-color: #4CAF50;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width:100%;
        text-align: center;
        &:hover, &:focus {
            background-color: #45a049;
        }
    }
}