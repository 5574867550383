
@import '../../../styles/variables';
.about{
    #banner{
        height:160px;
    }
    .flex{
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $break1) { 
            flex-direction: row;
        } 
        div.video{
            padding-top: 30px;
        }
    }
    h1, h2{
        font-family: Arial, Helvetica, sans-serif;
    }
    h2{
        text-align: left;
        span{
            font-size: 0.5em;
        }
    }
    .cta{
        display: none;
    }
    iframe{
        width: 100%;
        @media screen and (min-width: $break1) { 
          min-width: 560px;
        } 
    }
}