#logo{ //h1 logo
    display: flex;    
    align-items: center;
    align-self: center;
    img{
        width: 300px;
        transition-duration: 0.1s;
        &:hover{
            transform: scale(1.05);            
        }
    }
}