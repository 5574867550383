@import '../../../../styles/variables';
.main-data{
    .cta{        
        *{            
            font-size: 0.8em;
        }
    }
}   
#banner{           
    .cta{
        max-width: 455px;
        margin:0 auto;            
    }
    button.btn{
        margin-bottom: 10px;
        border:none;
        @media screen and (min-width: $break1) { 
            margin-bottom: 0;
        }         
    }
}
.cta{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
    @media screen and (min-width: $break1) {
        flex-direction: row;
    }    
}
.btn {
    background: $main;
    display: inline-block;
    transition-duration: 0.1s;
    text-align: center;
    margin: 0 20px;
    padding: 17px;
    border-radius: 4px !important;
    color: #fff;
    letter-spacing: 3px;
    cursor: pointer;
    &.red{
        background: $red-bg;
    }
    &:hover, &:focus{
        transform: scale(1.2);
        text-decoration: underline;
    }
}