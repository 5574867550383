
.social-icons{
    ul{        
        li{
            display: inline-block;
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }        
    }
    a{
        display: inline-block;
        cursor: pointer;
        &:hover, &:focus{
            transform: scale(1.5);
        }
    }
}