//font face styles
@font-face {
    font-family: "proxima-nova";
    src: url('../assets/fonts/proxima_nova.ttf');

}
@font-face {
    font-family: "ahellya";
    src: url('../assets/fonts/ahellya.ttf');
}
