
@import '../../../../styles/variables';
@import '../../../../styles/keyframes';



#banner{    
    height: 100vh;
    max-height: 563px;
    background-size: cover;
    animation: banner-animation-mobile 35s infinite;
    @media screen and (min-width: $break1) { 
        animation: banner-animation-desktop 25s infinite; 
        height: 489px;
    }     
    .flex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    h1 {
        color: #fff;
        margin: 0;
        text-shadow: 1px 1px 1px #000;
        margin:0 auto;
        @media screen and (min-width: $break1) { 
            font-size: 3em;
        } 
        span {
            text-transform: capitalize;
            font-size: 0.4em;
            display: block;
        }
    }
    .container{
    }
    .main-data{
        padding: 10px 15px;
        background: #0000007d;
        text-align: center; display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media screen and (min-width: $break1){
            flex-direction:row;
        }  
        span{
            color:#fff;
        }
    }    
}