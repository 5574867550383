
.video-page{
    .container.video-list {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        padding: 20px 0;
        background: #f7f7f7;
        border-radius: 20px;
    }
    .video-frame {
        margin: 20px 10px;
    }
}