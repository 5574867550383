.front{
    #banner{        
        display: flex;
        flex-direction:column;        
        align-items: center;
        justify-content:center;
        .main-data{
            background:none;
        }
        .container{
            min-height:auto;
            align-items: center;
            justify-content: space-evenly;
        }
        
    }
}