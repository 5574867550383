@import '../../../../styles/variables';
.social-contact{
    ul{        
        li{
            display: inline-block;
            margin-right: 15px;
            &:last-child{
                margin-right: 0;
            }
        }        
    }
    a{
        display: inline-block;
        font-size: 0.9em;
        &.phone{
            img{
                display: none;
                @media screen and (min-width: $break1) {
                    display: inline-block;
                }
            }
        }
        &:hover{
            transform: scale(1.05);
            text-decoration: underline;
        }
    }
}