@import '../../../../styles/variables';
nav{
    display: block;
    text-align: center;
    margin-bottom: 5px;
    @media screen and (min-width: $break1) { 
        margin-bottom: 0;
    }
    .icons {
        display: inline;
        @media screen and (min-width: $break1) { 
            display: none;
        }
    }
    ul#links{
        display: flex;
        justify-content: flex-end;    
        padding: 5px 0;
        list-style: none;                
        display: none;
        flex-wrap:wrap;
        @media screen and (min-width: $break1) { 
            display: flex;            
        }        
        a{
            text-transform: u                                                                                                                                                                                                                                                                                               ppercase;
            padding: 10px;
            margin: 0 10px;
            color: $txt;
            //letter-spacing: 0.1em;
            font-size: 0.94em;
            transition-duration: 0.1s;
            display: block;
            cursor: pointer;
            &:hover, &:focus,  &.active{
                text-decoration: underline; 
                transform: scale(1.2);                
            }
        }
    }
    //mobile
    .close{
        display: none;
    }    
}
.mobile{
    .close{
        display: inline-block;
        right: 0;
        top:0;
        position: absolute;
        padding: 20px;
        font-size: 2em;
        z-index: 9;
        color: #fff;        
    }
    .open{
        display: none;
    }
    #links{
        display: block;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        background: $main;
        width: 100vw;
        height: 100vh;
        a{
            color: #fff;
            display: block;
            padding: 29px;
            font-size: 1.5em; 
            transition-Duration: 0.1s;
            &:hover, &:active, &:focus{
                text-decoration: underline;                
                transform: scale(1.2);
            }           
        }
        
    }
}