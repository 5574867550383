//Palette
$txt : #282828;
$red: #fa532f;
$red-bg:#a52323;
$main: #222550;
$green:#4CAF50;
//media queries break
$break1: 768px;
$break2: 920px;
$break3: 1300px;
