h1, h2, h3 , h4, h5{
    text-align: center;
    margin: 0;
}
h1 {
    letter-spacing: 3px;
    font-size: 2.3em;
}
h2{
    font-size: 3em;
    color: $main;
}
h3{
    font-size: 2em;
    color: $main;
    span{
        display: block;
        font-size: 0.5em;
    }
}