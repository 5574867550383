@keyframes banner-animation-desktop {
	0% {
        background-position: 0 100%;    
	}
	80% { 
		background-position: 0 0% ;    
    }    
    100%{
        background-position: 0 100%;    
	}
}

@keyframes banner-animation-mobile {
	0% {
        background-position: 100% 0;  
	}
	80% { 
		background-position: 0% 0 ;    
    }    
    100%{
        background-position: 100% 0;    
    }
    
}
