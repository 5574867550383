@import '../../../../styles/variables';
footer{
    background: $main;
    width: 100%;    
    text-align: center;
    padding: 20px 0 40px 0;
    margin:40px 0 0 0;
    bottom: 0;
    height: 100px;
    a, i, p{
        color: #fff;
    }
    P{
        font-size:0.9em;
    }
    span{
        text-transform: uppercase;        
    }
    .social-icons{
        color:#000;
        i{            
            font-size: 2em;
            padding: 20px;
            cursor: pointer;
        }
        img{
            width: 40px;
        }
    }
}