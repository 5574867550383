@import './styles/font-face';
@import './styles/variables';
@import './styles/headings';
@import './styles/forms';
.App{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.hidden{
    position: absolute;
    width: rem(1);
    height: rem(1);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    word-wrap: normal;
    left:-2000px;
}
.only-desktop{
    display:none;
    @media screen and (min-width: $break1) {
        display: inline-block;
    }
}
p{
    line-height: 22px;  
}
i{
    color: #fff;
}
ul{
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    transition-duration: 0.1s;
}
hr{
    border: 1px solid #f5f5f5;
}
.label{
    font-weight: bold;
}
main {
    min-height: 68vh;
}
.close{
    background: none;
    border: none;
    transition-duration: 0.1s;
    &:hover, &:focus{
        transform: scale(1.7);
    }
}
body{
    font-family: "proxima-nova" !important;
    letter-spacing: 1px;
    margin:0;
    background:#fdfdfd;
    color: $txt;
}

.container, .content-container{
    max-width: 1200px;
    margin: 0 auto;
    width: 90%;    
}

@media screen and (min-width: $break1) {
    header {
        nav{  
            padding: 20px 0 10px 0;
                a{
                    text-transform: uppercase;
            }
        }
    }
}
/*
@media screen and (min-width: 1300px) {

}
@media screen and (min-width: 1700px)  {
}
@media screen and (min-width: 2100px) {

}*/