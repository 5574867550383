@import '../../../../styles/variables';
header  {       
    .container{
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $break1) { 
            justify-content: space-between;
            flex-direction: row;
        } 
    } 
    .top-header{        
        background: $main;
        height: 25px;
        .container{
            flex-direction: row;
        }
        a{
            color: #fff;            
        }                
        i{
            padding: 4px;
        }
    }    
}