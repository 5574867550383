@import '../../../styles/_variables.scss';
.connect{
    position: fixed;
    z-index: 1;
    padding-top: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: $main;
    transition-duration: 0.1s;    
    
    .container{        
        position: relative;
        max-width: 750px;
    }
    .contact-form{
        padding-top: 80px;
        max-width: 520px;
        margin: 0 auto;
        form{
            div{
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
            }
        }
    }
    h1, button, button.submit{
        color: #fff;
    }
    h1{
        margin: 0 0 20px 0;
    }
    .close{
        cursor: pointer;
        position: absolute;
        right: 0;
        top:0;
        font-size: 2em;
    }
    textarea{
        min-height: 100px;
    }
    .submit{
        background: $green;        
        padding: 20px 12px;
        width: 100%;
        border-radius: 5px;
        border: none;
    }
    .confirm-message{
        font-size: 3em;
        color: #fff;
        padding: 20px 0;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-self: center;
        align-items: center;
    }
}