@import '../../../styles/variables';
.investment-property {
    p.full-description {
        max-width: 920px;
        margin: 0 auto;
        text-align: center;
    }
    iframe{
        width: 80%;
        max-height: 550px;
        min-height: 550px;
        margin: 0 auto;
        display: block;
    }
    .main-data{
        div{
            display: flex;
            align-self: center;
        }
    }
    .featured-data{
        .featured-data-block{
            display: flex;
            flex-direction: column;
            margin:40px 0 10px 0;
            @media screen and (min-width: $break1){
                flex-direction: row;
                justify-content: center;
            }
            div{
                display: flex;
                border-bottom: 1px solid #ccc;
                flex-direction: column;
                align-items: center;
                padding: 10px 0;
                @media screen and (min-width: $break1){
                    border-bottom: none;
                    padding:0 15px ;                
                }
            }
            .label{
                font-size: 2em;
                color: $main;
            }
        }
    }    
}
#investments{
    .property-block{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media screen and (min-width: $break1){
            flex-direction: row;
        }
        h3{
            color: #fff;            
            text-align: center;
            line-height: 22px;
            font-family: "ahellya";
            transition-duration: 0.1s;
            margin: 10px 0 0 0;
            background: transparentize( #000000, 0.7);     
            padding: 5px 8px 3px;
            position: absolute;
            bottom: 110px;
            width: 95%;
            @media screen and (min-width: $break1){
                font-size: 1.6vw;
                padding: 5px 10px 3px;
            }
            span {
                font-size: 0.3em;
                display: block  ;
                text-transform: uppercase;
                letter-spacing: 4px;       
                font-family: "proxima-nova";
            }        
        }
        img{
            width: 100%;
            min-height: 190px;
            max-height: 190px;
        }
        .block{
            position: relative;
            &:hover, &:focus{                        
                cursor: pointer;
                h3{
                    font-size: 2em; 
                    padding-top: 20px;
                    background: $main;
                }           
            }
            .block-img-txt{
                color: #000;
               // margin: 0 5px;
            }
        }
        .main-block{       
            display: block;
            margin:0 auto;            
            @media screen and (min-width: $break1){
                width: 33.3%;
                margin: 0 10px 0 0;
            }
            a{
                min-height: 300px;
                display: block;
                position: relative;
            }
            p{
                font-size: 1em;
              //  color: #fff;
                margin: 0;
            }
            .btn{
                float: right;
                border: 1px solid #fff;
                color: #fff;
            }
        }
        .sec-blocks{
            width: 65%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;    
            //justify-content: space-between;
            img{
                //height: 213px;
            }
            .block{
                width: 33.3%;
            }
            .block-img-txt{
                right: 0;
            }
        }
    }
    
}
